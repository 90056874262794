import { useEffect, useState } from 'react';
import { countriesFetch } from '../../utils/signUpUtils';
import { ErrorMessage } from '@hookform/error-message';
import { useForm } from 'react-hook-form';
import { db } from '../../firebase';
import firebase from 'firebase/compat';
import { increment } from 'firebase/firestore';

export function CartDefaultAddress({ user, add, exchangeRate }: any) {
  const [countries, setCountries] = useState<Array<string>>([]);
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      paymentMethod: add.data.paymentMethod,
      shippingType: add.data.shippingType,
      recipient: add.data.recipient,
      recipientPhoneNumber: add.data.recipientPhoneNumber,
      recipientEmail: add.data.recipientEmail,
      street: add.data.street,
      city: add.data.city,
      states: add.data.states,
      country: add.data.country,
      zipcode: add.data.zipcode
    }
  });

  const [carts, setCarts] = useState<Array<any>>([]);

  const onSubmit = async (data: any) => {
    if (carts.length < 1) {
      return alert('Cart is empty');
    }
    const reducedCarts = carts.reduce((a, c) => {
      // console.log('c', c);
      if (c.data.optioned) {
        const index = a.findIndex((cart: any) => cart.optionId === c.data.optionId);
        if (index !== -1) {
          a[index].title = c.data.title;
          a[index].quan = a[index].quan + c.data.quan;
          a[index].productId = c.data.productId;
          a[index].optionId = c.data.optionId;
          a[index].optionName = c.data.optionName;
        } else if (index === -1) {
          const obj = {
            title: c.data.title,
            productId: c.data.productId,
            quan: c.data.quan,
            optionId: c.data.optionId,
            optionName: c.data.optionName
          };
          a.push(obj);
        }
        return a;
      } else {
        const index = a.findIndex((cart: any) => cart.productId === c.data.productId);
        if (index !== -1) {
          a[index].title = c.data.title;
          a[index].quan = a[index].quan + c.data.quan;
          a[index].productId = c.data.productId;
        } else if (index === -1) {
          const obj = {
            title: c.data.title,
            productId: c.data.productId,
            quan: c.data.quan
          };
          a.push(obj);
        }
        return a;
      }
    }, []);
    // console.log('reducedCarts', reducedCarts);
    const outOfStocks = await Promise.all(
      reducedCarts.map(async (c: any) => {
        const product: any = await db
          .collection('products')
          .doc(c.productId)
          .get()
          .then((doc) => doc.data());
        // console.log('product', product);

        if (product.limitedStock === false) {
          // console.log('false');
          return null;
        } else {
          // console.log('true');
          const stock = product.stock;
          const quan = c.quan;
          const title = product.title;

          if (product.optioned) {
            // if (product.limitedStock === true) {
            const optionProduct: any = await db
              .collection('products')
              .doc(c.productId)
              .collection('options')
              .doc(c.optionId)
              .get()
              .then((doc) => doc.data());
            return optionProduct.optionStock > quan
              ? null
              : `${product.title}(${optionProduct.optionName})`;
            // } else {
            //   return null;
            // }
          } else {
            // console.log('no option', stock, quan, title);
            return stock >= quan ? null : title;
          }
        }
      })
    );
    // console.log('outOfStocks', outOfStocks);
    if (outOfStocks.filter((doc) => doc !== null).length > 0) {
      return alert(
        `${outOfStocks.filter(
          (doc) => doc !== null
        )} The products are out of stock. Please delete it and order again.`
      );
    }

    if (confirm('Gonna order?')) {
      const today = new Date();
      const addName = add.data.name;
      // 주소록 저장
      await db.collection('accounts').doc(user.email).collection('addresses').doc(add.id).update({
        paymentMethod: data.paymentMethod,
        shippingType: data.shippingType,
        recipient: data.recipient,
        recipientPhoneNumber: data.recipientPhoneNumber,
        recipientEmail: data.recipientEmail,
        street: data.street,
        city: data.city,
        states: data.states,
        country: data.country,
        zipcode: data.zipcode
      });
      // 카트 -> 주문으로
      await Promise.all(
        await carts
          .reduce((a: any, c: any) => {
            c.data.addName = addName;
            c.data.createdAt = today;
            c.data.userId = user.email;
            c.data.userUid = user.uid;
            c.data.currency = user.currency;
            c.data.exchangeRate = exchangeRate;
            c.data.paymentMethod = data.paymentMethod;
            c.data.shippingType = data.shippingType;
            c.data.country = data.country;
            a.push(c);
            return a;
          }, [])
          .map(async (cart: any) => {
            await db
              .collection('accounts')
              .doc(user.email)
              .collection('order')
              .doc(cart.id)
              .set(cart.data);
            if (cart.data.optioned) {
              await db
                .collection('products')
                .doc(cart.data.productId)
                .collection('options')
                .doc(cart.data.optionId)
                .collection('newStockHistory')
                .doc(cart.id)
                .set(cart.data);
              return;
            }
            await db
              .collection('products')
              .doc(cart.data.productId)
              .collection('newStockHistory')
              .doc(cart.id)
              .set(cart.data);
          })
      );

      // 카트삭제
      await Promise.all(
        carts.map(
          async (cart: any) =>
            await db.collection('accounts').doc(user.email).collection('cart').doc(cart.id).delete()
        )
      );
      // 재고 적용
      await Promise.all(
        carts.map(async (cart: any) => {
          const products = await db.collection('products').doc(cart.data.productId).get();
          const product: any = products.data();
          if (product.optioned === true) {
            await db
              .collection('products')
              .doc(cart.data.productId)
              .collection('options')
              .doc(cart.data.optionId)
              .update({
                optionStock: increment(cart.data.quan * -1)
              });
            return;
          }
          await db
            .collection('products')
            .doc(cart.data.productId)
            .update({
              stock: product.stock - cart.data.quan,
              totalSold: product.totalSold + cart.data.quan,
              totalStock: product.totalStock - cart.data.quan,
              stockHistory: firebase.firestore.FieldValue.arrayUnion({
                type: 'sell on B2B',
                writer: user.nickName || user.email,
                amount: cart.data.quan,
                date: new Date()
              })
            });
        })
      );

      alert('Order completed');
    } else {
      return;
    }
  };

  useEffect(() => {
    db.collection('accounts')
      .doc(user.email)
      .collection('cart')
      .onSnapshot((snapshot) =>
        setCarts(snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() })))
      );
    countriesFetch(setCountries);
    setValue('paymentMethod', add.data.paymentMethod);
    setValue('shippingType', add.data.shippingType);
    setValue('recipient', add.data.recipient);
    setValue('recipientPhoneNumber', add.data.recipientPhoneNumber);
    setValue('recipientEmail', add.data.recipientEmail);
    setValue('street', add.data.street);
    setValue('city', add.data.city);
    setValue('states', add.data.states);
    setValue('country', add.data.country);
    setValue('zipcode', add.data.zipcode);
  }, [user.email, add]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <button
        type="submit"
        className=" bg-blue-900 rounded-sm text-gray-100 py-2 px-6 font-bold my-3">
        ORDER
      </button>

      {/* Payment Method */}
      <div className="flex flex-row items-center my-1">
        <div className="w-1/3 text-right">{'Payment Method : '}</div>
        <div className="w-2/3">
          <select
            {...register('paymentMethod', {
              required: { value: true, message: 'Payment Method is required.' }
            })}
            className="py-1 px-1 border rounded w-4/5 outline-none">
            <option value="credit">Bank Transfer(Credit)</option>
          </select>
          <ErrorMessage
            errors={errors}
            name="paymentMethod"
            render={({ message }) => (
              <div className="text-center font-semibold w-full text-red-600">{message}</div>
            )}
          />
        </div>
      </div>
      {/* Shipping Type */}
      <div className="flex flex-row items-center my-1">
        <div className="w-1/3 text-right">{'Shipping Type : '}</div>
        <div className="w-2/3">
          <select
            {...register('shippingType', {
              required: { value: true, message: 'Shipping Type is required.' }
            })}
            className="py-1 px-1 border rounded w-4/5 outline-none">
            <option value="dhl">DHL</option>
            <option value="EMS">EMS</option>
            <option value="UMAC(PH)">UMAC(PH)</option>
            <option value="CJ Logisticd">CJ Logisticd</option>
          </select>
          <ErrorMessage
            errors={errors}
            name="shippingType"
            render={({ message }) => (
              <div className="text-center font-semibold w-full text-red-600">{message}</div>
            )}
          />
        </div>
      </div>
      {/* Recipient  */}
      <div className="flex flex-row items-center my-1">
        <div className="w-1/3 text-right">{'Recipient : '}</div>
        <div className="w-2/3">
          <input
            {...register('recipient', {
              required: { value: true, message: 'Recipient is required.' },
              maxLength: { value: 50, message: 'Too long.' },
              minLength: { value: 1, message: 'Too short.' }
            })}
            type="text"
            placeholder="Recipient"
            className="py-1 px-2 border rounded w-4/5 outline-none"
          />
          <ErrorMessage
            errors={errors}
            name="recipient"
            render={({ message }) => (
              <div className="text-center font-semibold w-full text-red-600">{message}</div>
            )}
          />
        </div>
      </div>
      {/* Recipient PhoneNumber */}
      <div className="flex flex-row items-center my-1">
        <div className="w-1/3 text-right">{'Recipient PhoneNumber : '}</div>
        <div className="w-2/3">
          <input
            {...register('recipientPhoneNumber', {
              required: { value: true, message: 'Recipient PhoneNumber is required.' },
              maxLength: { value: 50, message: 'Too long.' },
              minLength: { value: 1, message: 'Too short.' }
            })}
            type="number"
            placeholder="Recipient PhoneNumber"
            className="py-1 px-2 border rounded w-4/5 outline-none"
          />
          <ErrorMessage
            errors={errors}
            name="recipientPhoneNumber"
            render={({ message }) => (
              <div className="text-center font-semibold w-full text-red-600">{message}</div>
            )}
          />
        </div>
      </div>
      {/* Recipient Email */}
      <div className="flex flex-row items-center my-1">
        <div className="w-1/3 text-right">{'Recipient Email : '}</div>
        <div className="w-2/3">
          <input
            {...register('recipientEmail', {
              required: { value: true, message: 'Recipient Email is required.' },
              maxLength: { value: 50, message: 'Too long.' },
              minLength: { value: 1, message: 'Too short.' }
            })}
            type="text"
            placeholder="Recipient Email"
            className="py-1 px-2 border rounded w-4/5 outline-none"
          />
          <ErrorMessage
            errors={errors}
            name="recipientEmail"
            render={({ message }) => (
              <div className="text-center font-semibold w-full text-red-600">{message}</div>
            )}
          />
        </div>
      </div>
      {/* Street  */}
      <div className="flex flex-row items-center my-1">
        <div className="w-1/3 text-right">{'Street : '}</div>
        <div className="w-2/3">
          <input
            {...register('street', {
              required: { value: true, message: 'Street is required.' },
              maxLength: { value: 50, message: 'Too long.' },
              minLength: { value: 1, message: 'Too short.' }
            })}
            type="text"
            placeholder="Street"
            className="py-1 px-2 border rounded w-4/5 outline-none"
          />
          <ErrorMessage
            errors={errors}
            name="street"
            render={({ message }) => (
              <div className="text-center font-semibold w-full text-red-600">{message}</div>
            )}
          />
        </div>
      </div>
      {/* City */}
      <div className="flex flex-row items-center my-1">
        <div className="w-1/3 text-right">{'City : '}</div>
        <div className="w-2/3">
          <input
            {...register('city', {
              required: { value: true, message: 'City is required.' },
              maxLength: { value: 50, message: 'Too long.' },
              minLength: { value: 1, message: 'Too short.' }
            })}
            type="text"
            placeholder="City"
            className="py-1 px-2 border rounded w-4/5 outline-none"
          />
          <ErrorMessage
            errors={errors}
            name="city"
            render={({ message }) => (
              <div className="text-center font-semibold w-full text-red-600">{message}</div>
            )}
          />
        </div>
      </div>
      {/* States */}
      <div className="flex flex-row items-center my-1">
        <div className="w-1/3 text-right">{'States : '}</div>
        <div className="w-2/3">
          <input
            {...register('states', {
              required: { value: true, message: 'State is required.' },
              maxLength: { value: 50, message: 'Too long.' },
              minLength: { value: 1, message: 'Too short.' }
            })}
            type="text"
            placeholder="States"
            className="py-1 px-2 border rounded w-4/5 outline-none"
          />
          <ErrorMessage
            errors={errors}
            name="states"
            render={({ message }) => (
              <div className="text-center font-semibold w-full text-red-600">{message}</div>
            )}
          />
        </div>
      </div>
      {/* Country */}
      <div className="flex flex-row items-center my-1">
        <div className="w-1/3 text-right">{'Country : '}</div>
        <div className="w-2/3">
          <select
            {...register('country', {
              required: { value: true, message: 'Country is required.' }
            })}
            className="py-1 px-1 border rounded w-4/5 outline-none">
            {countries?.sort().map((co: string, i: number) => (
              <option key={i} value={co}>
                {co}
              </option>
            ))}
          </select>
          <ErrorMessage
            errors={errors}
            name="country"
            render={({ message }) => (
              <div className="text-center font-semibold w-full text-red-600">{message}</div>
            )}
          />
        </div>
      </div>
      {/* Zip Code */}
      <div className="flex flex-row items-center my-1">
        <div className="w-1/3 text-right">{'Zip Code : '}</div>
        <div className="w-2/3">
          <input
            {...register('zipcode', {
              required: { value: true, message: 'Zip Code is required.' },
              maxLength: { value: 50, message: 'Too long.' },
              minLength: { value: 1, message: 'Too short.' }
            })}
            type="text"
            placeholder="Zip Code"
            className="py-1 px-2 border rounded w-4/5 outline-none"
          />
          <ErrorMessage
            errors={errors}
            name="zipcode"
            render={({ message }) => (
              <div className="text-center font-semibold w-full text-red-600">{message}</div>
            )}
          />
        </div>
      </div>
    </form>
  );
}
